import React, { useState, useEffect } from "react";
import "./ProfilePage.css";

const ProfilePage = ({ apiUrl }) => {
    const [userData, setUserData] = useState({});
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [passwordMessage, setpasswordMessage] = useState('');
    const [emailMessage, setemailMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    const fetchUserData = async () => {
        const token = sessionStorage.getItem('jwt_token');

        try {
            const response = await fetch(`${apiUrl}/api/user_info`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const data = await response.json();
            if (response.ok) {
                setUserData(data);
            }
        } catch (error) {
        }
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        const token = sessionStorage.getItem('jwt_token');

        try {
            const response = await fetch(`${apiUrl}/api/change_password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    old_password: oldPassword,
                    new_password: newPassword
                })
            });

            const data = await response.json();
            if (response.ok) {
                setpasswordMessage('Password updated successfully');
                setMessageType('success');
            } else {
                setpasswordMessage(data.error || 'Failed to update password');
                setMessageType('error');
            }
        } catch (error) {
            console.error('Error:', error);
            setpasswordMessage('An error occurred during the request.');
            setMessageType('error');
        }
    };

    const handleChangeEmail = async (e) => {
        e.preventDefault();
        const token = sessionStorage.getItem('jwt_token');

        try {
            const response = await fetch(`${apiUrl}/api/change_email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    new_email: newEmail
                })
            });

            const data = await response.json();
            if (response.ok) {
                setemailMessage('Email updated successfully');
                setMessageType('success');
                fetchUserData();
            } else {
                setemailMessage(data.error || 'Failed to update email');
                setMessageType('error');
            }
        } catch (error) {
            console.error('Error:', error);
            setemailMessage('An error occurred during the request.');
            setMessageType('error');
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const language = localStorage.getItem('language');

    return (
        <div className="profile-container">
            <h1>{language === 'english' ? 'Profile' : '个人信息'}</h1>
            <table className="profile-table">
                <tbody>
                    <tr>
                        <td>{language === 'english' ? 'Username:' : '用户名：'}</td>
                        <td>{userData.username}</td>
                    </tr>
                    <tr>
                        <td>{language === 'english' ? 'Email:' : '邮箱：'}</td>
                        <td>{userData.email}</td>
                    </tr>
                </tbody>
            </table>

            <form className="change-form" onSubmit={handleChangeEmail}>
                <h2>{language === 'english' ? 'Change Email' : '更改邮箱'}</h2>
                <input
                    type="email"
                    placeholder={language === 'english' ? 'New Email' : '新邮箱'}
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    required
                />
                <button type="submit">{language === 'english' ? 'Change Email' : '更改邮箱'}</button>
                {emailMessage && <p className={`message ${messageType}`}>{emailMessage}</p>}
            </form>

            <form className="change-form" onSubmit={handleChangePassword}>
                <h2>{language === 'english' ? 'Change Password' : '更改密码'}</h2>
                <input
                    type="password"
                    placeholder={language === 'english' ? 'Old Password' : '旧密码'}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder={language === 'english' ? 'New Password' : '新密码'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
                <button type="submit">{language === 'english' ? 'Change Password' : '更改密码'}</button>
                {passwordMessage && <p className={`message ${messageType}`}>{passwordMessage}</p>}
            </form>
        </div>
    );
};

export default ProfilePage;
