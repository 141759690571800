import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import TopToolbar from './ui_components/TopToolbar';
import LoginPage from './ui_components/AccountPages/LoginPage';
import RegisterPage from './ui_components/AccountPages/RegisterPage';
import AlertList from './ui_components/AlertPages/AlertList';
import ProfilePage from './ui_components/AccountPages/ProfilePage';
import LearningPage from './ui_components/AccountPages/LearningPage';
import MainPage from './ui_components/MainPages/MainPage';
import MarketPage from './ui_components/Marketpages/MarketPage';

function App() {
  // const apiUrl = "http://localhost:2000"
  const apiUrl = "http://34.172.176.230:8000"

  if (localStorage.getItem("language") === null) {
    localStorage.setItem("language", "english")
  }

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return sessionStorage.getItem('isLoggedIn') === 'true';
  });

  useEffect(() => {
    sessionStorage.setItem('isLoggedIn', isLoggedIn);
  }, [isLoggedIn]);

  return (
    <Router>
      <div className="App">
        <TopToolbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} apiUrl={apiUrl} />
        <Routes>
          <Route path="/login" element={<LoginPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} apiUrl={apiUrl} />} />
          <Route path="/register" element={<RegisterPage apiUrl={apiUrl} />} />
          <Route path="/alert-list" element={<AlertList apiUrl={apiUrl} />} />
          <Route path="/settings" element={<ProfilePage apiUrl={apiUrl} />} />
          <Route path="/" element={<MainPage isLoggedIn={isLoggedIn}/>} />
          {/* <Route path="/" element={<LoginPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} apiUrl={apiUrl}></LoginPage>} /> */}
          <Route path="/market" element={<MarketPage />} />
          <Route path="/learn" element={<LearningPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} apiUrl={apiUrl}></LearningPage>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
