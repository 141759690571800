import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginForm.css';

const LoginForm = ({ onLoginSuccess, apiUrl }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const language = localStorage.getItem('language');

    const isFormValid = () => {
        return username.trim() !== '' && password.trim() !== '';
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            setError(language === 'english' ? "Please enter both username and password" : "请输入用户名和密码");
            return;
        }
        try {
            const response = await fetch(apiUrl + '/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    password
                })
            });

            if (response.ok) {
                const data = await response.json();
                const { user_id, access_token, email } = data;
                sessionStorage.setItem('jwt_token', access_token);
                sessionStorage.setItem('userEmail', email);
                sessionStorage.setItem('userId', user_id);
                // console.log(sessionStorage.getItem('userId'))
                onLoginSuccess(sessionStorage.getItem('userId'));
                navigate('/alert-list');
            } else {
                const errorData = await response.json();
                setError(errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            setError(language === 'english' ? "Failed to connect to the server." : "无法连接服务器");
        }
    };

    return (
        <div className="login-form">
            <h2>{language === 'english' ? 'Login' : '登录'}</h2>
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={handleLogin}>
                <div className="login-form-group">
                    <label htmlFor="username">{language === 'english' ? 'User ID' : '用户名'}</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="login-form-group">
                    <label htmlFor="password">{language === 'english' ? 'Password' : '密码'}</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button className={isFormValid() ? 'login-button' : 'disabled-button'}>{language === 'english' ? 'Login' : '登录'}</button>
            </form>
        </div>
    );
};

export default LoginForm;
