import React from 'react';
import LoginForm from './LoginForm';

const LoginPage = ({ isLoggedIn, setIsLoggedIn, apiUrl }) => {
    const language = localStorage.getItem('language');

    if (isLoggedIn) {
        return (
            <div>
                <h2>{language === 'english' ? 'You are already logged in.' : '您已登录'}</h2>
            </div>
        );
    }

    return (
        <div>
            <h2>{language === 'english' ? 'Login' : '登录'}</h2>
            <LoginForm onLoginSuccess={() => setIsLoggedIn(true)} apiUrl={apiUrl} />
        </div>
    );
};

export default LoginPage;
