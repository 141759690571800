import React, { useState } from "react";
import "./CreateAlert.css";
import indicatorTypes from "./IndicatorTypes.json";
import patternTypes from "./PatternTypes.json";

const CreateAlert = ({ onClose, apiUrl }) => {
    const userEmail = sessionStorage.getItem("userEmail");
    const token = sessionStorage.getItem('jwt_token');
    const indicators = indicatorTypes;
    const language = localStorage.getItem('language');
    const [showSection1, setShowSection1] = useState(true);

    const [indicatorFormData, setIndicatorFormData] = useState({
        alert_name: "",
        email: userEmail,
        duration: "",
        sym: "",
        indicator_type: "",
        param1: "",
        param2: "",
        param3: "",
        alert_type: "",
        alert_value: "",
        indicator_freq: 1
    });


    const [patternFormData, setPatternFormData] = useState({
        alert_name: "",
        email: userEmail,
        duration: "",
        sym: "",
        indicator_type: "",
        param1: null,
        param2: null,
        param3: null,
        alert_type: "",
        alert_value: null,
        indicator_freq: 1
    });

    const handleIndicatorChange = (e) => {
        const { name, value } = e.target;
        if (name === "indicator_type" && value !== "") {
            const indicatorDefaults = indicators[value];
            const defaultValues = {
                param1: indicatorDefaults.params.param1 ? indicatorDefaults.params.param1[2] : null,
                param2: indicatorDefaults.params.param2 ? indicatorDefaults.params.param2[2] : null,
                param3: indicatorDefaults.params.param3 ? indicatorDefaults.params.param3[2] : null
            };
            setIndicatorFormData(prevState => ({
                ...prevState,
                [name]: value,
                ...defaultValues
            }));
        } else {
            setIndicatorFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handlePatternChange = (e) => {
        const { name, value } = e.target;
        if (name === "indicator_type" && value !== "") {
            setPatternFormData(prevState => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            setPatternFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const handleIndicatorSubmit = async (e) => {
        console.log('Success:', JSON.stringify(indicatorFormData));

        e.preventDefault();
        try {
            const response = await fetch(apiUrl + '/api/indicator_subscription', {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(indicatorFormData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            setIndicatorFormData({
                alert_name: "",
                email: userEmail,
                duration: "",
                sym: "",
                indicator_type: "",
                param1: "",
                param2: "",
                param3: "",
                alert_type: "",
                alert_value: "",
                indicator_freq: 1
            });

            onClose();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handlePatternSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(apiUrl + '/api/indicator_subscription', {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(patternFormData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            console.log('Success:', responseData);

            setPatternFormData({
                alert_name: "",
                email: userEmail,
                duration: "",
                sym: "",
                indicator_type: "",
                param1: null,
                param2: null,
                param3: null,
                alert_type: "",
                alert_value: null,
                indicator_freq: 1
            });

            onClose();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleToggleSection = () => {
        setShowSection1(prevState => !prevState);
    };

    return (
        <div className="popup">
            {showSection1 ? (
                <div className="popup-inner">
                    <h2>{language === 'english' ? 'Create Indicator Alert' : '创建指标警报'}</h2>
                    <form onSubmit={handleIndicatorSubmit}>
                        <div className="form-group">
                            <label htmlFor="alert_name">{language === 'english' ? 'Alert Name:' : '名称：'}</label>
                            <input className="form-input" name="alert_name" value={indicatorFormData.alert_name} onChange={handleIndicatorChange} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="sym">{language === 'english' ? 'Crypto:' : '加密货币：'}</label>
                            <select className="form-input" name="sym" value={indicatorFormData.sym} onChange={handleIndicatorChange} required>
                                <option value="">{language === 'english' ? 'Select Crypto' : '选择加密货币'}</option>
                                <option value="BNB-USDT">BNB-USDT</option>
                                <option value="BTC-USDT">BTC-USDT</option>
                                <option value="DOGE-USDT">DOGE-USDT</option>
                                <option value="ETH-USDT">ETH-USDT</option>
                                <option value="SOL-USDT">SOL-USDT</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="duration">{language === 'english' ? 'Duration:' : '周期：'}</label>
                            <select className="form-input" name="duration" value={indicatorFormData.duration} onChange={handleIndicatorChange} required>
                                <option value="">{language === 'english' ? 'Select Duration' : '选择周期'}</option>
                                <option value="1m">1m</option>
                                <option value="5m">5m</option>
                                <option value="15m">15m</option>
                                <option value="15m">1H</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="indicator_type">{language === 'english' ? 'Indicator Type:' : '指标类型：'}</label>
                            <select className="form-input" name="indicator_type" value={indicatorFormData.indicator_type} onChange={handleIndicatorChange} required>
                                <option value="">{language === 'english' ? 'Select Indicator Type' : '选择指标类型'}</option>
                                {Object.keys(indicators).map(key => (
                                    <option key={key} value={key}>{key}</option>
                                ))}
                            </select>
                        </div>
                        {indicatorFormData.indicator_type && indicators[indicatorFormData.indicator_type] && Object.entries(indicators[indicatorFormData.indicator_type].params).map(([param, params]) => (
                            params && (
                                <div className="form-group" key={param}>
                                    <label htmlFor={param}>{language === 'english' ? params[0] : params[1]}:</label>
                                    <input
                                        className="form-input"
                                        name={param}
                                        value={indicatorFormData[param]}
                                        onChange={handleIndicatorChange}
                                    />
                                </div>
                            )
                        ))}
                        <div className="form-group">
                            <label htmlFor="alert_type">{language === 'english' ? 'Alert Type:' : '警报类型：'}</label>
                            <select className="form-input" name="alert_type" value={indicatorFormData.alert_type} onChange={handleIndicatorChange} required>
                                <option value="">{language === 'english' ? 'Select Alert Type' : '选择警报类型'}</option>
                                <option value="crossing">{language === 'english' ? 'Crossing' : '穿过(Crossing)'}</option>
                                <option value="crossing-up">{language === 'english' ? 'Crossing Up' : '上穿(Crossing Up)'}</option>
                                <option value="crossing-down">{language === 'english' ? 'Crossing Down' : '下穿(Crossing Down)'}</option>
                                <option value="greater">{language === 'english' ? 'Greater Than' : '大于(Greater Than)'}</option>
                                <option value="less">{language === 'english' ? 'Less Than' : '小于(Less Than)'}</option>
                                {indicatorFormData.indicator_type &&
                                    indicators[indicatorFormData.indicator_type] &&
                                    indicators[indicatorFormData.indicator_type].alert_type &&
                                    Object.entries(indicators[indicatorFormData.indicator_type].alert_type).map(([key, value]) => (
                                        <option key={key} value={key}>
                                            {language === 'english' ? value[0] : value[1]}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="alert_value">{language === 'english' ? 'Alert Value:' : '警报值：'}</label>
                            <input type="number" className="form-input" name="alert_value" value={indicatorFormData.alert_value} onChange={handleIndicatorChange} required />
                        </div>
                        <div>
                            <button className="submit-button" type="submit">{language === 'english' ? 'Submit' : '创建'}</button>
                            <button className="close-button" type="button" onClick={onClose}>{language === 'english' ? 'Close' : '关闭'}</button>
                        </div>
                    </form>
                    <button className="toggle-button" type="button" onClick={handleToggleSection}>{language === 'english' ? 'Pattern' : '形态警报'}</button>
                </div>) : (<div className="popup-inner">
                    <h2>{language === 'english' ? 'Create Pattern Alert' : '创建图形警报'}</h2>
                    <form onSubmit={handlePatternSubmit}>
                        <div className="form-group">
                            <label htmlFor="alert_name">{language === 'english' ? 'Alert Name:' : '警报名称：'}</label>
                            <input className="form-input" name="alert_name" value={patternFormData.alert_name} onChange={handlePatternChange} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="sym">{language === 'english' ? 'Crypto:' : '加密货币：'}</label>
                            <select className="form-input" name="sym" value={patternFormData.sym} onChange={handlePatternChange} required>
                                <option value="">{language === 'english' ? 'Select Crypto' : '选择加密货币'}</option>
                                <option value="BNB-USDT">BNB-USDT</option>
                                <option value="BTC-USDT">BTC-USDT</option>
                                <option value="DOGE-USDT">DOGE-USDT</option>
                                <option value="ETH-USDT">ETH-USDT</option>
                                <option value="SOL-USDT">SOL-USDT</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="duration">{language === 'english' ? 'Duration:' : '周期：'}</label>
                            <select className="form-input" name="duration" value={patternFormData.duration} onChange={handlePatternChange} required>
                                <option value="">{language === 'english' ? 'Select Duration' : '选择周期'}</option>
                                <option value="1m">1m</option>
                                <option value="5m">5m</option>
                                <option value="15m">15m</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="indicator_type">{language === 'english' ? 'Pattern Type:' : '图形类型：'}</label>
                            <select className="form-input" name="indicator_type" value={patternFormData.indicator_type} onChange={handlePatternChange} required>
                                <option value="">{language === 'english' ? 'Select Pattern Type' : '选择图形类型'}</option>
                                {Object.entries(patternTypes).map(([key, value]) => (
                                    <option key={key} value={key}>
                                        {language === 'english' ? value.name[0] : value.name[1]}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <button className="submit-button" type="submit">{language === 'english' ? 'Submit' : '创建'}</button>
                            <button className="close-button" type="button" onClick={onClose}>{language === 'english' ? 'Close' : '关闭'}</button>
                        </div>
                    </form>
                    <button className="toggle-button" type="button" onClick={handleToggleSection}>{language === 'english' ? 'Close' : '指标警报'}</button>
                </div>
            )}
        </div>
    );
};

export default CreateAlert;
