import React, { useState, useEffect } from 'react';
import { cci, cmo, moneyFlowIndex, ppo, rsi, sma, vwap, willr } from 'indicatorts';
import { calculateADX, calculateCCI, calculateCMO, calculateMFI, calculateRSI } from '../../techinal_analysis/techinal_indicators';
import './MarketPage.css'; // Import the CSS file for styling

function GetData(instId, bar) {
    const [candles, setCandles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openPrice, setOpenPrice] = useState([]);
    const [highPrice, setHighPrice] = useState([]);
    const [lowPrice, setLowPrice] = useState([]);
    const [closePrice, setClosePrice] = useState([]);
    const [volume, setVolume] = useState([]);

    useEffect(() => {
        const fetchCandles = async () => {
            console.log(bar);
            const limit = 100;
            try {
                const response = await fetch(`https://www.okx.com/api/v5/market/history-candles?instId=${instId}&bar=${bar}&limit=${limit}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const responseData = await response.json();
                setCandles(responseData);

                const openPriceArray = responseData.data.map(candle => parseFloat(candle[1])).reverse();
                const highPriceArray = responseData.data.map(candle => parseFloat(candle[2])).reverse();
                const lowPriceArray = responseData.data.map(candle => parseFloat(candle[3])).reverse();
                const closePriceArray = responseData.data.map(candle => parseFloat(candle[4])).reverse();
                const volumeArray = responseData.data.map(candle => parseFloat(candle[5])).reverse();

                setOpenPrice(openPriceArray);
                setHighPrice(highPriceArray);
                setLowPrice(lowPriceArray);
                setClosePrice(closePriceArray);
                setVolume(volumeArray);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        // Perform an immediate fetch when the component mounts
        fetchCandles();

        // Set up the interval to fetch at the start of every minute
        const scheduleFetch = () => {
            const now = new Date();
            const delay = 60500 - (now.getSeconds() * 1000 + now.getMilliseconds());

            const timer = setTimeout(() => {
                fetchCandles();
                const intervalId = setInterval(fetchCandles, 60500);
                return () => clearInterval(intervalId);
            }, delay);

            return () => clearTimeout(timer);
        };

        const clear = scheduleFetch();

        return () => clear();
    }, [bar]);


    // console.log(volume[volume.length - 1])
    const adxResult = calculateADX(highPrice, lowPrice, closePrice, 14)
    const cciResult = calculateCCI(highPrice, lowPrice, closePrice, 20);
    const { ppoResult, signal, histogram } = ppo(closePrice, { fast: 10, slow: 21 });
    const rsiResult = calculateRSI(closePrice, 5);

    const smaResult = sma(closePrice, { period: 5 });

    const mfiResult = calculateMFI(highPrice, lowPrice, closePrice, volume, 5);
    const cmoResult = calculateCMO(closePrice, 9)
    // console.log("cmo", adxResult);
    // console.log('r', rsi(closePrice, { period: 14 }))
    const trend = closePrice[closePrice.length - 1] < closePrice[closePrice.length - 2] ? 'green' : 'red';


    if (mfiResult != undefined) {
        return {
            crypto: instId,
            openPrice: openPrice[openPrice.length - 1],
            highPrice: highPrice[highPrice.length - 1],
            lowPrice: lowPrice[lowPrice.length - 1],
            closePrice: closePrice[closePrice.length - 1],
            volume: volume[volume.length - 1],
            change: (closePrice[closePrice.length - 1] - closePrice[closePrice.length - 2]).toFixed(6),
            changePercent: ((closePrice[closePrice.length - 1] - closePrice[closePrice.length - 2]) / closePrice[closePrice.length - 1] * 100).toFixed(4),
            rsi: (rsiResult[rsiResult.length - 1]).toFixed(3),
            adx: (adxResult[adxResult.length - 1]).toFixed(3),
            cci: (cciResult[cciResult.length - 1]).toFixed(3),
            cmo: (cmoResult).toFixed(3),
            mfi: (mfiResult).toFixed(3),
            ppo: (ppoResult[ppoResult.length - 1]).toFixed(3),
            sma: (smaResult[smaResult.length - 1]).toFixed(3),
            trend: trend,
        };
    } else {
        return { crypto: instId }
    }
}

function MarketPage() {
    const [timeFrame, setTimeFrame] = useState('1m');

    const sol = GetData('SOL-USDT', timeFrame);
    const btc = GetData('BTC-USDT', timeFrame);
    const eth = GetData('ETH-USDT', timeFrame);
    const bnb = GetData('BNB-USDT', timeFrame);
    const doge = GetData('DOGE-USDT', timeFrame);

    let cryptoData = [];
    cryptoData.push(bnb);
    cryptoData.push(btc);
    cryptoData.push(doge);
    cryptoData.push(eth);
    cryptoData.push(sol);

    return (
        <div className="crypto-table">
            <div className="timeframe-selector">
                {['1m', '5m', '15m', '1H'].map((frame) => (
                    <button
                        key={frame}
                        onClick={() => setTimeFrame(frame)}
                        className={timeFrame === frame ? 'active' : ''}
                    >
                        {frame}
                    </button>
                ))}
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Crypto</th>
                        <th>Open</th>
                        <th>High</th>
                        <th>Low</th>
                        <th>Close</th>
                        <th>Volume</th>
                        <th>Change</th>
                        <th>Change %</th>
                        <th>RSI</th>
                        <th>ADX</th>
                        <th>CCI</th>
                        <th>CMO</th>
                        <th>MFI</th>
                        <th>PPO</th>
                        <th>MA</th>
                    </tr>
                </thead>
                <tbody>
                    {cryptoData.map((item, index) => (
                        <tr key={index} className={item.trend === 'green' ? 'bull-trend' : 'bear-trend'}>
                            <td>{item.crypto}</td>
                            <td>{item.openPrice}</td>
                            <td>{item.highPrice}</td>
                            <td>{item.lowPrice}</td>
                            <td>{item.closePrice}</td>
                            <td>{item.volume}</td>
                            <td>{item.change}</td>
                            <td>{item.changePercent}</td>
                            <td>{item.rsi}</td>
                            <td>{item.adx}</td>
                            <td>{item.cci}</td>
                            <td>{item.cmo}</td>
                            <td>{item.mfi}</td>
                            <td>{item.ppo}</td>
                            <td>{item.sma}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default MarketPage;
