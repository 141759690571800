import React, { useState } from 'react';
import './RegisterForm.css';

const RegisterForm = ({apiUrl}) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [showTermsPopup, setShowTermsPopup] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const language = localStorage.getItem('language');

    const isFormValid = () => {
        return username !== "" && email !== "" && password.trim() !== '' && confirmPassword !== "" && agreedToTerms;
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        if (!isFormValid()) {
            setMessage(language === 'english' ? "Please fill out the form" : "请填写表格");
            return;
        }

        if (password !== confirmPassword) {
            setMessage(language === 'english' ? "Passwords do not match" : "密码不匹配");
            return;
        }

        try {
            const response = await fetch(apiUrl + '/api/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    email,
                    password
                })
            });

            if (response.ok) {
                setMessage(language === 'english' ? "Registered! You can now login." : "注册成功！您现在可以登录。");
                setUsername('');
                setEmail('');
                setPassword('');
                setConfirmPassword('');
            } else {
                const errorData = await response.text();
                try {
                    const jsonError = JSON.parse(errorData);
                    setMessage(jsonError.message);
                } catch {
                    setMessage(language === 'english' ? "An error occurred while processing your request." : "处理您的请求时发生错误。");
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage(language === 'english' ? "Failed to connect to the server." : "无法连接到服务器。");
        }
    };


    return (
        <div className="register-form">
            <h2>{language === 'english' ? 'Register' : '注册'}</h2>
            {message && <div className={message.startsWith(language === 'english' ? "Registered" : "注册成功") ? "success-message" : "error-message"}>{message}</div>}
            <form onSubmit={handleRegister}>
                <div className="register-form-group">
                    <label htmlFor="username">{language === 'english' ? 'Username' : '用户名'}</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="register-form-group">
                    <label htmlFor="email">{language === 'english' ? 'Email' : '邮箱'}</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="register-form-group">
                    <label htmlFor="password">{language === 'english' ? 'Password' : '密码'}</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="register-form-group">
                    <label htmlFor="confirmPassword">{language === 'english' ? 'Confirm Password' : '确认密码'}</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                <div className="terms-agreement">
                    <input
                        type="checkbox"
                        id="agreeToTerms"
                        checked={agreedToTerms}
                        onChange={() => setAgreedToTerms(!agreedToTerms)}
                    />
                    <label htmlFor="agreeToTerms">{language === 'english' ? 'I Agree to the ' : '我同意'}</label>
                    <a href="/termsConditions" target="_blank">{language === 'english' ? 'Terms & Agreement' : '条款与协议'}</a>
                </div>
                <button type="submit" className={isFormValid() ? 'register-button' : 'disabled-button'}>{language === 'english' ? 'Register' : '注册'}</button>
            </form>
        </div>
    );
};

export default RegisterForm;
