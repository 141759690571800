import React from 'react';
import RegisterForm from './RegisterForm';

const RegisterPage = ({apiUrl}) => {
    const language = localStorage.getItem('language');
    return (
        <div className="account-page">
            <h2>{language === 'english' ? 'Register' : '注册'}</h2>
            <RegisterForm apiUrl={apiUrl} />
        </div>
    );
};

export default RegisterPage;
