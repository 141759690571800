import React from 'react';
import './MainPage.css';
import { Link } from 'react-router-dom';
import imageSrc from '../../images/main_page.jpg';

const MainPage = ({ isLoggedIn }) => {
    const language = localStorage.getItem('language');

    return (
        <>
            <div className="content">
                <div className="alert-box">
                    <h1>{language === 'english' ? 'With real-time indicator alerts' : '实时指标提醒，把握时机'}</h1>
                    <h1>{language === 'english' ? 'Unlock your trading potential' : '释放您的交易潜能'}</h1>
                    <h1>{language === 'english' ? 'Keep ahead of market trends' : '紧跟市场趋势，抢先一步'}</h1>
                    <Link to={isLoggedIn ? "/alert-list" : "/register"}>
                        <button className="create-alert-button">{language === 'english' ? 'Create Alert' : '创建警报'}</button>
                    </Link>
                    {/* <div className="paragraph-content">
                        <p>{language === 'english' ? 'With an extensive array of indicators and a variety of alert types, we provide timely updates and alerts to help you make informed decisions in the dynamic world of market. Whether you are a seasoned professional or just starting out, our platform equips you with the tools you need to stay ahead of the curve in the markets.' :
                            '当您在寻求应对市场波动的可靠解决方案时，我们的平台是您的理想之选。我们提供海量数据指标和警报类型，覆盖市场的各个角落，确保您能够捕捉到每一个重要的变化。不论您是已经经验丰富的专业人士还是刚刚踏入投资领域的新手，我们都致力于为您提供所需的工具和资源。通过实时更新和警报帮助您在激烈的竞争中保持领先地位，并做出明智决策。'}</p>
                    </div> */}
                </div>
                <img src={imageSrc} alt="Main Image" className="main-image" />
            </div>
        </>
    );
}

export default MainPage;
