function calculateADX(highp, lowp, closep, period) {
    if (highp.length >= period) {
        let tr = [];
        let pdm = [];
        let ndm = [];

        // Calculate True Range, Plus Directional Movement, and Negative Directional Movement
        for (let i = 1; i < highp.length; i++) {
            const currentTR = Math.max(highp[i] - lowp[i], Math.abs(highp[i] - closep[i - 1]), Math.abs(lowp[i] - closep[i - 1]));
            tr.push(currentTR);

            const upMove = highp[i] - highp[i - 1];
            const downMove = lowp[i - 1] - lowp[i];
            if (upMove > downMove && upMove > 0) {
                pdm.push(upMove);
            } else {
                pdm.push(0);
            }
            if (downMove > upMove && downMove > 0) {
                ndm.push(downMove);
            } else {
                ndm.push(0);
            }
        }

        // Calculate smoothed TR, Plus DM, and Negative DM
        let smoothedTR = tr.slice(0, period).reduce((a, b) => a + b, 0);
        let smoothedPDM = pdm.slice(0, period).reduce((a, b) => a + b, 0);
        let smoothedNDM = ndm.slice(0, period).reduce((a, b) => a + b, 0);
        let adx = [];
        for (let i = period; i < highp.length; i++) {
            smoothedTR = smoothedTR - (smoothedTR / period) + tr[i];
            smoothedPDM = smoothedPDM - (smoothedPDM / period) + pdm[i];
            smoothedNDM = smoothedNDM - (smoothedNDM / period) + ndm[i];
            // console.log('t', (smoothedTR))

            const plusDI = (smoothedPDM / smoothedTR) * 100;
            const minusDI = (smoothedNDM / smoothedTR) * 100;

            const dx = (Math.abs(plusDI - minusDI) / (plusDI + minusDI)) * 100;
            adx.push(dx);
        }

        // Smooth the DX to get ADX
        let smoothedADX = adx.slice(0, period - 1).reduce((a, b) => a + b, 0) / period;
        let finalADX = [smoothedADX];
        for (let i = period; i < adx.length - 1; i++) {
            smoothedADX = ((smoothedADX * (period - 1)) + adx[i]) / period;
            finalADX.push(smoothedADX);
        }
        // console.log(finalADX)
        return finalADX;
    } else {
        return null; // Not enough data to calculate ADX
    }
}

function calculateMFI(highp, lowp, closep, volume, period) {
    if (highp.length > 0) {
        let moneyFlows = [];
        for (let i = 0; i < highp.length; i++) {
            const typicalPrice = ((highp[i] + lowp[i] + closep[i]) / 3);
            const rawMoneyFlow = typicalPrice * volume[i];
            moneyFlows.push({
                typicalPrice: typicalPrice,
                rawMoneyFlow: rawMoneyFlow
            });
        }

        let positiveFlow = 0;
        let negativeFlow = 0;

        // Calculate positive and negative money flows
        for (let i = moneyFlows.length - period; i < moneyFlows.length; i++) {
            // console.log(moneyFlows[i - 1].typicalPrice)
            if (moneyFlows[i].typicalPrice > moneyFlows[i - 1].typicalPrice) {
                positiveFlow += moneyFlows[i].rawMoneyFlow;
            } else {
                negativeFlow += moneyFlows[i].rawMoneyFlow;
            }
        }
        const moneyFlowRatio = positiveFlow / negativeFlow;
        const mfi = 100 - (100 / (1 + moneyFlowRatio));
        return mfi;
    }
}

function calculateCCI(highp, lowp, closep, period) {
    if (highp.length >= period) {
        let typicalPrices = [];
        let movingAverages = [];
        let meanDeviations = [];

        // Calculate typical prices
        for (let i = 0; i < highp.length; i++) {
            const typicalPrice = (highp[i] + lowp[i] + closep[i]) / 3;
            typicalPrices.push(typicalPrice);
        }

        // Calculate simple moving averages of typical prices
        for (let i = period - 1; i < highp.length; i++) {
            let sum = 0;
            for (let j = i - period + 1; j <= i; j++) {
                sum += typicalPrices[j];
            }
            const average = sum / period;
            movingAverages.push(average);

            // Calculate mean deviation for the same period
            let meanDeviationSum = 0;
            for (let j = i - period + 1; j <= i; j++) {
                meanDeviationSum += Math.abs(typicalPrices[j] - average);
            }
            const meanDeviation = meanDeviationSum / period;
            meanDeviations.push(meanDeviation);
        }

        // Calculate CCI for each period
        let cciValues = [];
        for (let i = 0; i < movingAverages.length; i++) {
            const cci = (typicalPrices[i + period - 1] - movingAverages[i]) / (0.015 * meanDeviations[i]);
            cciValues.push(cci);
        }

        return cciValues;
    } else {
        return null;
    }
}

function calculateCMO(closePrices, period) {
    if (closePrices.length >= period) {
        let sumGains = 0;
        let sumLosses = 0;

        for (let i = closePrices.length - period; i < closePrices.length; i++) {
            const priceChange = closePrices[i] - closePrices[i - 1];
            if (priceChange > 0) {
                sumGains += priceChange;
            } else {
                sumLosses -= priceChange;
            }
        }

        // Calculate the Chande Momentum Oscillator
        const cmo = ((sumGains - sumLosses) / (sumGains + sumLosses)) * 100;

        return cmo;
    } else {
        return null;
    }
}

function calculateRSI(prices, period) {
    if (prices.length > period) {
        let gains = [];
        let losses = [];

        // Calculate gains and losses
        for (let i = 1; i < prices.length; i++) {
            const change = prices[i] - prices[i - 1];
            if (change > 0) {
                gains.push(change);
                losses.push(0);
            } else {
                gains.push(0);
                losses.push(-change);
            }
        }

        // Calculate initial averages of gains and losses
        let averageGain = gains.slice(0, period).reduce((a, b) => a + b, 0) / period;
        let averageLoss = losses.slice(0, period).reduce((a, b) => a + b, 0) / period;

        let rsi = []; // To store RSI values

        // Calculate RSI using smoothed moving average
        for (let i = period; i < prices.length - 1; i++) {
            averageGain = (averageGain * (period - 1) + gains[i]) / period;
            averageLoss = (averageLoss * (period - 1) + losses[i]) / period;

            if (averageLoss === 0) {
                rsi.push(100); // RSI is 100 if there are no losses
            } else {
                const rsiValue = 100 - (100 / (1 + averageGain / averageLoss));
                rsi.push(rsiValue);
            }
        }
        // console.log(rsi)
        return rsi;
    } else {
        return null; // Not enough data to calculate RSI
    }
}

export { calculateADX, calculateCCI, calculateCMO, calculateMFI, calculateRSI }

