import React, { useState, useEffect } from "react";
import "./AlertList.css";
import CreateAlert from "./CreateAlert";

const AlertList = ({ apiUrl }) => {
    const [alerts, setAlerts] = useState([]);
    const token = sessionStorage.getItem('jwt_token');
    const [showPopup, setShowPopup] = useState(false);
    const language = localStorage.getItem('language');

    const fetchAlerts = () => {
        fetch(apiUrl + '/api/indicator_subscription', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {setAlerts(data)
                            console.log(data)})
            .catch(error => console.error('Error fetching data:', error));
    };

    useEffect(() => {
        fetchAlerts();
    }, []);

    const handleCreate = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        fetchAlerts(); // Fetch alerts again after closing the popup
    };

    // console.log(alerts);

    const handleDelete = (id) => {
        fetch(apiUrl + `/api/indicator_subscription/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    setAlerts(alerts.filter(alert => alert.id !== id));
                } else {
                    console.error('Failed to delete alert');
                }
            })
            .catch(error => console.error('Error deleting alert:', error));
    };

    return (
        <div className = "alert-container">
            <button className="alert-button" onClick={handleCreate}>
                {language === 'english' ? 'Create Alert' : '创建警报'}
            </button>
            {showPopup && <CreateAlert onClose={handleClosePopup} apiUrl={apiUrl} />}
            <table className="alert-table">
                <thead>
                    <tr>
                        <th>{language === 'english' ? 'Name' : '名称'}</th>
                        <th>{language === 'english' ? 'Crypto' : '加密货币'}</th>
                        <th>{language === 'english' ? 'Duration' : '周期'}</th>
                        <th>{language === 'english' ? 'Indicator' : '指标'}</th>
                        <th>{language === 'english' ? 'Params' : '参数'}</th>
                        <th>{language === 'english' ? 'Alert Type' : '警报类型'}</th>
                        <th>{language === 'english' ? 'Alert Value' : '警报值'}</th>
                        <th>{language === 'english' ? 'Delete' : '删除'}</th>
                    </tr>
                </thead>
                <tbody>
                    {alerts.length > 0 ? (
                        alerts.map(alert => (
                            <tr key={alert.id}>
                                <td>{alert.alert_name}</td>
                                <td>{alert.sym}</td>
                                <td>{alert.duration}</td>
                                <td>{alert.indicator_type}</td>
                                <td>
                                    {alert.param1 && `${alert.param1}`}
                                    {alert.param2 && `, ${alert.param2}`}
                                    {alert.param3 && `, ${alert.param3}`}
                                </td>
                                <td>{alert.alert_type}</td>
                                <td>{alert.alert_value}</td>
                                <td>
                                    <button onClick={() => handleDelete(alert.id)}>
                                        {language === 'english' ? 'Delete' : '删除'}
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="8">
                                {language === 'english' ? 'You don\'t have any alerts yet!' : '您还没有任何警报！'}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default AlertList;
