import React, { useState, useEffect } from 'react';
import './TopToolbar.css';
import { useNavigate } from 'react-router-dom';

const TopToolbar = ({ isLoggedIn, setIsLoggedIn }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'english');
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const signOut = () => {
    sessionStorage.removeItem('isLoggedIn');
    sessionStorage.removeItem('jwt_token');
    // setIsLoggedIn(false);

    // navigate("/")
  };

  const setLanguageAndStore = (selectedLanguage) => {
    localStorage.setItem('language', selectedLanguage);
    setLanguage(selectedLanguage);
  };

  return (
    <div className="top-toolbar">
      <h1>
        <a href="/">Tradicator</a>
      </h1>
      <nav>
        <ul>
          <li></li>
          <li><a href="/"></a></li>
          <li><div className="dropdown"><a href="/market">{language === 'english' ? 'Market' : '行情'}</a></div></li>
          {isLoggedIn && (
            <li>
              <div className="dropdown" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
                <a>{language === 'english' ? 'Alert' : '警报'}</a>
                {showDropdown && (
                  <div className="dropdown-content">
                    <a href="/alert-list" style={{ color: 'black' }}>{language === 'english' ? 'My Alerts' : '我的警报'}</a>
                  </div>
                )}
              </div>
            </li>
          )}
          <li><div className="dropdown"><a href="/learn">{language === 'english' ? 'Indicator Academy' : '指标学院'}</a></div></li>
          <li>
            <div className="dropdown" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
              <a>{language === 'english' ? 'Account' : '帐户'}</a>
              {showDropdown && (
                <div className="dropdown-content">
                  {isLoggedIn ? (
                    <>
                      <a href="/settings" style={{ color: 'black' }}>{language === 'english' ? 'Settings' : '设置'}</a>
                      <a href="/" onClick={signOut} style={{ color: 'red' }}>{language === 'english' ? 'Log Out' : '登出'}</a>
                    </>
                  ) : (
                    <>
                      <a href="/login" style={{ color: 'black' }}>{language === 'english' ? 'Login' : '登录'}</a>
                      <a href="/register" style={{ color: 'red' }}>{language === 'english' ? 'Register' : '注册'}</a>
                    </>
                  )}
                </div>
              )}
            </div>
          </li>
          <li>
            <div className="dropdown" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
              <a>{language === 'english' ? 'Language' : '语言'}</a>
              {showDropdown && (
                <div className="dropdown-content">
                  <a href="/" onClick={() => setLanguageAndStore('english')} style={{ color: 'black' }}>English</a>
                  <a href="/" onClick={() => setLanguageAndStore('chinese')} style={{ color: 'black' }}>中文</a>
                </div>
              )}
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default TopToolbar;
