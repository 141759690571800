import React from 'react';
import './LearningPage.css';

const language = localStorage.getItem('language');

function LearningPage() {
    return (
        <div className="learn-container">
            {language === 'english' ? (<>
                <h1 className='learn-title'>ADX:</h1>
                <p>
                    The ADX indicator is a technical analysis tool used to measure the strength of market trends. Its underlying logic is based on the concept of market trends, where prices typically show directional movement in an uptrend or downtrend. The ADX indicator helps traders determine the strength of this trend.
                </p>
                <h2 className='learn-sub-title'>Usage:</h2>
                <p>
                    <strong className="bold-text">Determining Trend:</strong> ADX values usually range between 0 and 100. The higher the value, the stronger the trend. Generally, an ADX value above 25 indicates a strong trend, while a value above 50 indicates a very strong trend.
                </p>
                <p>
                    <strong className="bold-text">Determining Trend Direction:</strong> The ADX indicator can also be combined with other technical analysis tools, such as the DMI indicator, to help determine the market trend direction. When the ADX rises, it indicates that the market trend is strengthening, and when the ADX falls, it may indicate that the market trend is weakening.
                </p>
                <p>
                    <strong className="bold-text">Developing Trading Strategies:</strong> Traders can develop trading strategies based on ADX values and trend direction. For example, when the ADX value is high and the trend is clear, one might choose to trade in the direction of the trend. When the ADX value is low or the trend is unclear, it may be better to wait or adopt range trading strategies.
                </p>

                <h1 className='learn-title'>APO:</h1>
                <p>
                    The APO (Absolute Price Oscillator) is a technical analysis tool used to measure the absolute changes in the prices of stocks or other financial assets and help traders identify the trend direction of prices. Its underlying logic is based on the absolute price changes and the differences between moving averages.
                </p>
                <h2 className='learn-sub-title'>Usage:</h2>
                <p>
                    <strong className="bold-text">Calculating Absolute Price Changes:</strong> APO calculates the absolute price changes by comparing the differences between two moving averages. Typically, the APO consists of the difference between two moving averages, which can be set according to the trader's preference, commonly using periods like 12 days and 26 days.
                </p>
                <p>
                    <strong className="bold-text">Identifying Trend Direction:</strong> The APO value represents the absolute amount of price change, with positive values indicating an uptrend and negative values indicating a downtrend. Traders can use changes in the APO value to determine the trend direction and strength of prices.
                </p>
                <p>
                    <strong className="bold-text">Determining Trading Opportunities:</strong> Traders can use changes in the APO value and trend direction to determine trading opportunities. For example, when the APO turns from negative to positive, it may be a buy signal; conversely, when the APO turns from positive to negative, it may be a sell signal.
                </p>

                <h1 className='learn-title'>Aroon:</h1>
                <p>
                    The Aroon indicator is a technical analysis tool used to help traders determine the strength and direction of market trends. Its underlying logic is based on the time it takes for prices to reach new highs and lows to measure the trendiness of the market.
                </p>
                <h2 className='learn-sub-title'>Usage:</h2>
                <p>
                    <strong className="bold-text">Determining Trend Direction:</strong> The Aroon indicator consists of two lines: the Aroon Up Line and the Aroon Down Line. The Aroon Up Line measures the time since the last high, and the Aroon Down Line measures the time since the last low. When the Aroon Up Line is above the Aroon Down Line, it indicates an uptrend; conversely, when the Aroon Down Line is above the Aroon Up Line, it indicates a downtrend.
                </p>
                <p>
                    <strong className="bold-text">Determining Trend Strength:</strong> The Aroon indicator can also measure the strength of the trend. When both the Aroon Up Line and Aroon Down Line have high values, it indicates a strong market trend; conversely, when both have low values, it indicates a relatively weak market trend.
                </p>
                <p>
                    <strong className="bold-text">Determining Trading Opportunities:</strong> Traders can use the trend and strength indicated by the Aroon indicator to determine trading opportunities. For example, when the Aroon Up Line is above the Aroon Down Line and both have high values, it may be a buy signal; when the Aroon Down Line is above the Aroon Up Line and both have low values, it may be a sell signal.
                </p>

                <h1 className='learn-title'>CCI:</h1>
                <p>
                    The CCI indicator is a technical analysis tool used to measure the deviation of prices from their statistical average. Its underlying logic is based on the concept of price volatility, where price changes within a relatively stable range can be measured using the standard deviation in statistics, and the CCI indicator uses this concept to determine whether prices are in an overbought or oversold state.
                </p>
                <h2 className='learn-sub-title'>Usage:</h2>
                <p>
                    <strong className="bold-text">Determining Overbought/Oversold:</strong> The CCI value typically fluctuates between -100 and +100. When the CCI value is above +100, it indicates that prices are in an overbought state, which may lead to a downtrend; when the CCI value is below -100, it indicates that prices are in an oversold state, which may lead to an uptrend.
                </p>
                <p>
                    <strong className="bold-text">Determining Trading Opportunities:</strong> Traders can use the CCI value to determine trading opportunities. For example, when the CCI value drops below +100 from an overbought area, it may be a sell signal; when the CCI value rises above -100 from an oversold area, it may be a buy signal.
                </p>
                <p>
                    <strong className="bold-text">Combining with Other Indicators:</strong> The CCI indicator is often used in conjunction with other technical analysis tools to improve the accuracy of trading decisions. For example, combining it with trend lines or support and resistance lines can help avoid misjudgments.
                </p>

                <h1 className='learn-title'>CMO:</h1>
                <p>
                    The CMO (Chande Momentum Oscillator) is a technical analysis tool used to measure the momentum of price changes and help traders identify overbought and oversold market conditions. Its underlying logic is based on the speed and strength of price changes.
                </p>
                <h2 className='learn-sub-title'>Usage:</h2>
                <p>
                    <strong className="bold-text">Measuring Momentum:</strong> The CMO calculates momentum by comparing the current price with the price a certain period ago. If the current price is higher than the previous period's price, the CMO is positive; if the current price is lower, the CMO is negative. This comparison reflects the speed and direction of price changes.
                </p>
                <p>
                    <strong className="bold-text">Identifying Overbought/Oversold:</strong> The CMO value typically fluctuates between -100 and +100. When the CMO value is above +50, it indicates an overbought market condition, which may lead to a downtrend; when the CMO value is below -50, it indicates an oversold market condition, which may lead to an uptrend.
                </p>
                <p>
                    <strong className="bold-text">Determining Trading Opportunities:</strong> Traders can use changes in the CMO value to determine trading opportunities. For example, when the CMO rises from the oversold area to positive values, it may be a buy signal; when the CMO falls from the overbought area to negative values, it may be a sell signal.
                </p>

                <h1 className='learn-title'>MA:</h1>
                <p>
                    The MA (Moving Average) is a technical analysis tool used to smooth price data and identify market trends. Its underlying logic is to reduce the impact of price fluctuations by calculating the average price over a certain period, thereby providing a clearer view of trends.
                </p>
                <h2 className='learn-sub-title'>Usage:</h2>
                <p>
                    <strong className="bold-text">Calculating Moving Average:</strong> The MA smooths price data by calculating the average price over a certain period. Common moving averages include the Simple Moving Average (SMA) and the Exponential Moving Average (EMA). The SMA calculates the average price over the past N trading days, while the EMA gives more weight to recent prices, making it more responsive to price changes.
                </p>
                <p>
                    <strong className="bold-text">Identifying Trend Direction:</strong> Moving averages can help traders identify the market trend direction. When the price is above the moving average, it usually indicates an uptrend; when the price is below the moving average, it usually indicates a downtrend. The crossover of moving averages can also be used as a buy or sell signal, for example, when a short-term MA crosses above a long-term MA, it may be a buy signal, and when a short-term MA crosses below a long-term MA, it may be a sell signal.
                </p>
                <p>
                    <strong className="bold-text">Determining Trading Opportunities:</strong> Traders can use changes in the moving average to determine trading opportunities. For example, when the price breaks above the moving average from below, it may be a buy signal; when the price breaks below the moving average from above, it may be a sell signal. Additionally, the slope of the moving average can provide information about the strength of the market trend; the steeper the slope, the stronger the trend usually is.
                </p>

                <h1 className='learn-title'>MFI:</h1>
                <p>
                    The MFI (Money Flow Index) is a technical analysis tool used to measure the inflow and outflow of funds in the market and help traders determine overbought and oversold conditions. Its underlying logic is to assess market money flow by combining price and volume.
                </p>
                <h2 className='learn-sub-title'>Usage:</h2>
                <p>
                    <strong className="bold-text">Calculating Money Flow:</strong> The MFI calculates money flow by combining price and volume. First, the typical price for each period is calculated, then multiplied by the volume to get the money flow. Money flow is classified as positive or negative based on the rise and fall of the typical price. Finally, the MFI value is obtained by calculating the ratio of these money flows.
                </p>
                <p>
                    <strong className="bold-text">Identifying Overbought/Oversold:</strong> The MFI value typically fluctuates between 0 and 100. When the MFI value is above 80, it indicates an overbought market condition, which may lead to a downtrend; when the MFI value is below 20, it indicates an oversold market condition, which may lead to an uptrend.
                </p>
                <p>
                    <strong className="bold-text">Determining Trading Opportunities:</strong> Traders can use changes in the MFI value to determine trading opportunities. For example, when the MFI rises above 20 from the oversold area, it may be a buy signal; when the MFI falls below 80 from the overbought area, it may be a sell signal. Additionally, MFI divergence (e.g., price reaching a new high while MFI does not) can also be used to determine potential trend reversals.
                </p>

                <h1 className='learn-title'>MOM:</h1>
                <p>
                    The MOM (Momentum) is a technical analysis tool used to measure the speed and strength of price changes and help traders identify momentum trends in the market. Its underlying logic is to evaluate the force of price changes by comparing the current price with the price from a past period.
                </p>
                <h2 className='learn-sub-title'>Usage:</h2>
                <p>
                    <strong className="bold-text">Calculating Momentum:</strong> The MOM calculates momentum by measuring the difference between the current price and the price N periods ago. Typically, the MOM value is calculated by subtracting the price N periods ago from the current price. This calculation reflects the speed and direction of price changes.
                </p>
                <p>
                    <strong className="bold-text">Identifying Trend Strength:</strong> The MOM value can help traders identify the strength of market trends. Positive values indicate that the current price is higher than the past price, indicating upward momentum; negative values indicate that the current price is lower, indicating downward momentum. The absolute size and speed of change in the MOM value can reflect the strength of market momentum.
                </p>
                <p>
                    <strong className="bold-text">Determining Trading Opportunities:</strong> Traders can use changes in the MOM value to determine trading opportunities. For example, when the MOM value turns from negative to positive, it may be a buy signal; when the MOM value turns from positive to negative, it may be a sell signal. Additionally, MOM divergence (e.g., price reaching a new high but MOM not reaching a new high) can also be used to determine potential trend reversals.
                </p>

                <h1 className='learn-title'>PPO:</h1>
                <p>
                    The PPO (Percentage Price Oscillator) is a technical analysis tool used to measure the percentage difference between two moving averages and help traders identify price trends and momentum. Its underlying logic is to evaluate market trends based on relative price changes.
                </p>
                <h2 className='learn-sub-title'>Usage:</h2>
                <p>
                    <strong className="bold-text">Calculating Percentage Price Oscillator:</strong> The PPO calculates the percentage difference between short-term and long-term moving averages. Typically, the PPO is calculated by subtracting the long-term EMA (e.g., 26-day EMA) from the short-term EMA (e.g., 12-day EMA), then dividing by the long-term EMA and multiplying by 100 to get the percentage.
                </p>
                <p>
                    <strong className="bold-text">Identifying Trend and Momentum:</strong> The PPO value can help traders identify trend direction and momentum. When the PPO value is above the zero line, it indicates that the short-term moving average is above the long-term moving average, usually implying upward momentum; when the PPO value is below the zero line, it indicates that the short-term moving average is below the long-term moving average, usually implying downward momentum.
                </p>
                <p>
                    <strong className="bold-text">Determining Trading Opportunities:</strong> Traders can use PPO crossovers and divergences to determine trading opportunities. For example, when the PPO line crosses above the signal line from below, it may be a buy signal; when the PPO line crosses below the signal line from above, it may be a sell signal.
                </p>

                <h1 className='learn-title'>RSI:</h1>
                <p>
                    The RSI (Relative Strength Index) is a technical analysis tool used to measure the relative strength of prices and help traders identify overbought and oversold market conditions. Its underlying logic is to evaluate market strength by calculating the average amplitude of price increases and decreases over a certain period.
                </p>
                <h2 className='learn-sub-title'>Usage:</h2>
                <p>
                    <strong className="bold-text">Calculating Relative Strength:</strong> The RSI calculates a value by comparing the average amplitude of price increases and decreases over a certain period, usually fluctuating between 0 and 100. The RSI formula is 100 minus (100 divided by 1 plus the relative strength value), where the relative strength value is the result of dividing the average gain by the average loss.
                </p>
                <p>
                    <strong className="bold-text">Identifying Overbought/Oversold:</strong> The RSI value can help identify overbought and oversold market conditions. When the RSI is above 70, it indicates that the market may be in an overbought state, which may lead to a price correction; when the RSI is below 30, it indicates that the market may be in an oversold state, which may lead to a price rebound.
                </p>
                <p>
                    <strong className="bold-text">Determining Trading Opportunities:</strong> Traders can use changes in the RSI value to determine trading opportunities. For example, when the RSI rises above 30 from the oversold area, it may be a buy signal; when the RSI falls below 70 from the overbought area, it may be a sell signal. Additionally, RSI divergence can also be used to determine trend reversals.
                </p>

                <h1 className='learn-title'>VWAP:</h1>
                <p>
                    The VWAP (Volume Weighted Average Price) is a technical analysis tool used to calculate the volume-weighted average price over a specific period and help traders assess the overall price level of the market. Its underlying logic is to calculate the weighted average price by considering the volume.
                </p>
                <h2 className='learn-sub-title'>Usage:</h2>
                <p>
                    <strong className="bold-text">Calculating Volume Weighted Average Price:</strong> The VWAP calculates the volume-weighted average price by multiplying the volume of each price point by the price, summing these products, and then dividing by the total volume. The VWAP calculation usually starts from the beginning of the trading day to the current time and updates continuously over time.
                </p>
                <p>
                    <strong className="bold-text">Assessing Market Price Levels:</strong> The VWAP can help traders assess the overall price level of the market. When the price is above the VWAP, it usually indicates an uptrend; when the price is below the VWAP, it usually indicates a downtrend. The VWAP can also be used as a reference for support and resistance levels.
                </p>
                <p>
                    <strong className="bold-text">Determining Trading Opportunities:</strong> Traders can use the position of the price relative to the VWAP to determine trading opportunities. For example, when the price breaks above the VWAP and continues to rise, it may be a buy signal; when the price breaks below the VWAP and continues to fall, it may be a sell signal.
                </p>

                <h1 className='learn-title'>Williams %R:</h1>
                <p>
                    The Williams %R (Williams Percent Range) is a technical analysis tool used to measure overbought and oversold market conditions. Its underlying logic is based on the relative position of the price between the highest and lowest points over a certain period.
                </p>
                <h2 className='learn-sub-title'>Usage:</h2>
                <p>
                    <strong className="bold-text">Calculating Percentage Range:</strong> The Williams %R calculates a value by measuring the relative position of the current price between the highest and lowest prices over a certain period. The formula is (highest price - current price) divided by (highest price - lowest price), then multiplied by -100. The result usually fluctuates between -100 and 0.
                </p>
                <p>
                    <strong className="bold-text">Identifying Overbought/Oversold:</strong> The Williams %R value can help identify overbought and oversold market conditions. When the Williams %R is below -80, it indicates an oversold market condition, which may lead to a price rebound; when the Williams %R is above -20, it indicates an overbought market condition, which may lead to a price correction.
                </p>
                <p>
                    <strong className="bold-text">Determining Trading Opportunities:</strong> Traders can use changes in the Williams %R value to determine trading opportunities. For example, when the Williams %R rises above -80 from the oversold area, it may be a buy signal; when the Williams %R falls below -20 from the overbought area, it may be a sell signal. Additionally, Williams %R divergence can also be used to determine potential trend reversals.
                </p>
            </>) : (
                <>
                    <h1 className='learn-title'>ADX:</h1>
                    <p>
                        ADX指标是一种技术分析工具,用于衡量市场趋势的强度。其背后的逻辑是基于市场趋势的概念,即在上升或下降趋势中,价格通常会呈现一定的方向性运动,而ADX指标的作用就是帮助交易者确定这种趋势的强度。
                    </p>
                    <h2 className='learn-sub-title'>使用方式:</h2>
                    <p>
                        <strong className="bold-text">确定趋势:</strong> ADX指标的数值通常介于0到100之间,越高表示趋势越强。一般来说,ADX数值超过25意味着趋势比较强劲,而超过50则表明极强的趋势。
                    </p>
                    <p>
                        <strong className="bold-text">判断趋势的方向:</strong> ADX指标还可以结合其他技术分析工具,如DMI指标,帮助判断市场的趋势方向。当ADX上升时,表示市场趋势正在加强,而当ADX下降时,则可能表明市场趋势正在减弱。
                    </p>
                    <p>
                        <strong className="bold-text">制定交易策略:</strong> 交易者可以根据ADX指标的数值和趋势方向制定相应的交易策略。例如,在ADX数值较高且趋势明显时,可以选择跟随趋势进行交易,而在ADX数值较低或趋势不明确时,则可能选择观望或采取范围交易策略。
                    </p>

                    <h1 className='learn-title'>APO:</h1>
                    <p>
                        APO（Absolute Price Oscillator）是一种技术分析工具，用于衡量股票或其他金融资产价格的绝对变化，并帮助交易者识别价格的趋势方向。其背后的逻辑是基于价格的绝对变化和均线之间的差异。
                    </p>
                    <h2 className='learn-sub-title'>使用方式:</h2>
                    <p>
                        <strong className="bold-text">计算绝对价格变化：</strong> APO通过比较两条移动平均线的差异来计算价格的绝对变化。通常，APO由两个移动平均线的差值组成，这两个移动平均线的周期可以根据交易者的偏好而设定，常见的周期包括12日和26日。
                    </p>
                    <p>
                        <strong className="bold-text">识别趋势方向：</strong> APO的数值代表价格的绝对变化量，正值表示价格上升趋势，负值表示价格下降趋势。交易者可以根据APO的数值变化来判断价格的趋势方向和强度。
                    </p>
                    <p>
                        <strong className="bold-text">判断买卖时机：</strong> 交易者可以根据APO指标的数值变化和趋势方向来确定买卖时机。例如，当APO由负值变为正值时，可能是买入信号；而当APO由正值变为负值时，可能是卖出信号。
                    </p>


                    <h1 className='learn-title'>Aroon:</h1>
                    <p>
                        Aroon指标是一种技术分析工具，用于帮助交易者确定市场趋势的强度和方向。其背后的逻辑是基于价格的新高点和新低点出现的时间来衡量市场的趋势性。
                    </p>
                    <h2 className='learn-sub-title'>使用方式:</h2>
                    <p>
                        <strong className="bold-text">确定趋势方向：</strong> Aroon指标由两条线组成，分别是Aroon上升线（Aroon Up Line）和Aroon下降线（Aroon Down Line）。Aroon上升线衡量新高点出现的时间，而Aroon下降线衡量新低点出现的时间。当Aroon上升线高于Aroon下降线时，表示市场处于上升趋势；反之，当Aroon下降线高于Aroon上升线时，表示市场处于下降趋势。
                    </p>
                    <p>
                        <strong className="bold-text">确定趋势强度：</strong> Aroon指标还可以用来衡量趋势的强度。当Aroon上升线和Aroon下降线的数值都较高时，表示市场趋势比较强劲；反之，当两者的数值都较低时，表示市场趋势相对较弱。
                    </p>
                    <p>
                        <strong className="bold-text">判断买卖时机：</strong> 交易者可以根据Aroon指标的趋势和强度来确定买卖时机。例如，在Aroon上升线高于Aroon下降线且两者数值较高时，可能是买入信号；而在Aroon下降线高于Aroon上升线且两者数值较低时，可能是卖出信号。
                    </p>

                    <h1 className='learn-title'>CCI:</h1>
                    <p>
                        CCI指标是一种技术分析工具，用于衡量价格相对于其统计平均数的偏差程度。其背后的逻辑是基于价格波动的概念，即价格在一个相对稳定的范围内波动时，可以用统计学上的标准差来衡量价格的变化幅度，而CCI指标则利用这一概念来判断价格是处于超买还是超卖状态。
                    </p>
                    <h2 className='learn-sub-title'>使用方式:</h2>
                    <p>
                        <strong className="bold-text">确定超买超卖：</strong> CCI指标的数值通常在-100到+100之间波动，当CCI数值高于+100时，意味着价格处于超买状态，可能会出现价格下跌的趋势；而当CCI数值低于-100时，意味着价格处于超卖状态，可能会出现价格上涨的趋势。
                    </p>
                    <p>
                        <strong className="bold-text">判断买卖时机：</strong> 交易者可以根据CCI指标的数值来判断买卖时机。例如，当CCI指标从超买区域回落到+100以下时，可能是卖出信号；而当CCI指标从超卖区域回升到-100以上时，可能是买入信号。
                    </p>
                    <p>
                        <strong className="bold-text">结合其他指标：</strong> CCI指标通常结合其他技术分析工具一起使用，以提高交易决策的准确性。例如，可以结合趋势线或支撑阻力线等进行确认，以避免误判。
                    </p>

                    <h1 className='learn-title'>CMO:</h1>
                    <p>
                        CMO（Chande Momentum Oscillator）是一种技术分析工具，用于衡量价格变动的动能，并帮助交易者识别市场的超买和超卖情况。其背后的逻辑基于价格变动的速度和力度。
                    </p>
                    <h2 className='learn-sub-title'>使用方式:</h2>
                    <p>
                        <strong className="bold-text">衡量动能：</strong> CMO通过比较当前价格和一定期间前的价格来计算动量。如果当前价格高于前一期的价格，则CMO为正值；如果当前价格低于前一期的价格，则CMO为负值。这种比较可以反映价格变动的速度和方向。
                    </p>
                    <p>
                        <strong className="bold-text">识别超买超卖：</strong> CMO的数值通常在-100到+100之间波动。当CMO数值高于+50时，表示市场处于超买状态，可能会出现价格下跌的趋势；而当CMO数值低于-50时，表示市场处于超卖状态，可能会出现价格上涨的趋势。
                    </p>
                    <p>
                        <strong className="bold-text">判断买卖时机：</strong> 交易者可以根据CMO指标的数值变化来判断买卖时机。例如，当CMO从超卖区域回升到正值时，可能是买入信号；而当CMO从超买区域回落到负值时，可能是卖出信号。
                    </p>

                    <h1 className='learn-title'>MA:</h1>
                    <p>
                        MA（Moving Average）是一种技术分析工具，用于平滑价格数据并识别市场趋势。其背后的逻辑是通过计算一定时间段内的平均价格来减少价格波动的影响，从而提供更清晰的趋势视图。
                    </p>
                    <h2 className='learn-sub-title'>使用方式:</h2>
                    <p>
                        <strong className="bold-text">计算移动平均：</strong> MA通过计算一定时间段内的平均价格来平滑价格数据。常见的移动平均线包括简单移动平均线（SMA）和指数移动平均线（EMA）。SMA计算的是过去N个交易日的平均价格，而EMA则对最近的价格给予更多的权重，从而对价格变化更敏感。
                    </p>
                    <p>
                        <strong className="bold-text">识别趋势方向：</strong> 移动平均线可以帮助交易者识别市场的趋势方向。当价格高于移动平均线时，通常表示市场处于上升趋势；而当价格低于移动平均线时，通常表示市场处于下降趋势。移动平均线的交叉也可以作为买入或卖出的信号，例如，当短期MA上穿长期MA时，可能是买入信号，而短期MA下穿长期MA时，可能是卖出信号。
                    </p>
                    <p>
                        <strong className="bold-text">判断买卖时机：</strong> 交易者可以根据移动平均线的变化来判断买卖时机。例如，当价格从下方突破移动平均线时，可能是买入信号；而当价格从上方跌破移动平均线时，可能是卖出信号。此外，移动平均线的斜率也可以提供关于市场趋势强度的信息，斜率越陡峭，趋势通常越强。
                    </p>

                    <h1 className='learn-title'>MFI:</h1>
                    <p>
                        MFI（Money Flow Index）是一种技术分析工具，用于衡量市场中的资金流入和流出情况，并帮助交易者判断市场的超买和超卖状态。其背后的逻辑是通过价格和成交量的结合来评估市场的资金流动。
                    </p>
                    <h2 className='learn-sub-title'>使用方式:</h2>
                    <p>
                        <strong className="bold-text">计算资金流：</strong> MFI通过结合价格和成交量来计算资金流。首先计算出每个周期的典型价格（Typical Price），然后乘以成交量得到资金流（Money Flow）。根据典型价格的涨跌，资金流分为正资金流（Positive Money Flow）和负资金流（Negative Money Flow）。最终通过计算这些资金流的比率来得到MFI值。
                    </p>
                    <p>
                        <strong className="bold-text">识别超买超卖：</strong> MFI的数值通常在0到100之间波动。当MFI数值高于80时，表示市场处于超买状态，可能会出现价格回调的趋势；而当MFI数值低于20时，表示市场处于超卖状态，可能会出现价格反弹的趋势。
                    </p>
                    <p>
                        <strong className="bold-text">判断买卖时机：</strong> 交易者可以根据MFI指标的数值变化来判断买卖时机。例如，当MFI从超卖区域回升到20以上时，可能是买入信号；而当MFI从超买区域回落到80以下时，可能是卖出信号。此外，MFI的背离现象（例如价格创出新高而MFI未创出新高）也可以用来判断潜在的趋势反转。
                    </p>

                    <h1 className='learn-title'>MOM:</h1>
                    <p>
                        MOM（Momentum）是一种技术分析工具，用于衡量价格变动的速度和强度，并帮助交易者识别市场的动量趋势。其背后的逻辑是通过比较当前价格与过去某一时期的价格来评估价格变动的力度。
                    </p>
                    <h2 className='learn-sub-title'>使用方式:</h2>
                    <p>
                        <strong className="bold-text">计算动量：</strong> MOM通过计算当前价格与过去N个周期的价格差异来衡量动量。通常，MOM值的计算公式为当前价格减去N周期前的价格。这种计算可以反映价格变动的速度和方向。
                    </p>
                    <p>
                        <strong className="bold-text">识别趋势强度：</strong> MOM的数值可以帮助交易者识别市场的趋势强度。正值表示当前价格高于过去的价格，表明上升动量；负值则表示当前价格低于过去的价格，表明下降动量。MOM值的绝对大小和变化速度可以反映市场动量的强度。
                    </p>
                    <p>
                        <strong className="bold-text">判断买卖时机：</strong> 交易者可以根据MOM指标的数值变化来判断买卖时机。例如，当MOM值由负转正时，可能是买入信号；而当MOM值由正转负时，可能是卖出信号。此外，MOM的背离现象（例如价格创出新高但MOM未创出新高）也可以用来判断潜在的趋势反转。
                    </p>

                    <h1 className='learn-title'>PPO:</h1>
                    <p>
                        PPO（Percentage Price Oscillator）是一种技术分析工具，用于衡量两条移动平均线之间的百分比差异，并帮助交易者识别价格的趋势和动量。其背后的逻辑是基于价格的相对变化来评估市场的趋势。
                    </p>
                    <h2 className='learn-sub-title'>使用方式:</h2>
                    <p>
                        <strong className="bold-text">计算百分比价格振荡：</strong> PPO通过计算短期和长期移动平均线的百分比差异来评估市场趋势。通常，PPO由短期EMA（如12日EMA）减去长期EMA（如26日EMA），然后除以长期EMA，再乘以100得出百分比。
                    </p>
                    <p>
                        <strong className="bold-text">识别趋势和动量：</strong> PPO的数值可以帮助交易者识别趋势方向和动量。当PPO值高于零线时，表示短期移动平均线高于长期移动平均线，通常意味着上升动量；当PPO值低于零线时，表示短期移动平均线低于长期移动平均线，通常意味着下降动量。
                    </p>
                    <p>
                        <strong className="bold-text">判断买卖时机：</strong> 交易者可以根据PPO的交叉和背离来判断买卖时机。例如，当PPO线由下向上突破信号线时，可能是买入信号；而当PPO线由上向下突破信号线时，可能是卖出信号。
                    </p>

                    <h1 className='learn-title'>RSI:</h1>
                    <p>
                        RSI（Relative Strength Index）是一种技术分析工具，用于衡量价格的相对强弱，并帮助交易者识别市场的超买和超卖状态。其背后的逻辑是通过计算一定时间段内价格上涨和下跌的平均幅度来评估市场的强弱。
                    </p>
                    <h2 className='learn-sub-title'>使用方式:</h2>
                    <p>
                        <strong className="bold-text">计算相对强弱：</strong> RSI通过计算一定时间段内价格上涨和下跌的平均幅度来得出一个值，通常在0到100之间波动。RSI的计算公式为100减去(100除以1加上相对强弱值)，其中相对强弱值是上涨平均值除以下跌平均值的结果。
                    </p>
                    <p>
                        <strong className="bold-text">识别超买超卖：</strong> RSI的数值可以帮助识别市场的超买和超卖状态。当RSI高于70时，表示市场可能处于超买状态，可能会出现价格回调；而当RSI低于30时，表示市场可能处于超卖状态，可能会出现价格反弹。
                    </p>
                    <p>
                        <strong className="bold-text">判断买卖时机：</strong> 交易者可以根据RSI的数值变化来判断买卖时机。例如，当RSI从超卖区域回升到30以上时，可能是买入信号；而当RSI从超买区域回落到70以下时，可能是卖出信号。此外，RSI的背离现象也可以用来判断趋势反转。
                    </p>

                    <h1 className='learn-title'>VWAP:</h1>
                    <p>
                        VWAP（Volume Weighted Average Price）是一种技术分析工具，用于计算某一特定时间段内的成交量加权平均价格，并帮助交易者评估市场的整体价格水平。其背后的逻辑是通过考虑成交量来计算价格的加权平均值。
                    </p>
                    <h2 className='learn-sub-title'>使用方式:</h2>
                    <p>
                        <strong className="bold-text">计算成交量加权平均价格：</strong> VWAP通过将每个价格点的成交量乘以该价格，然后将这些乘积求和，再除以总成交量来得出加权平均价格。VWAP的计算通常从交易日的开始到当前时间进行，随着时间的推移不断更新。
                    </p>
                    <p>
                        <strong className="bold-text">评估市场价格水平：</strong> VWAP可以帮助交易者评估市场的整体价格水平。当价格高于VWAP时，通常表示市场处于上升趋势；当价格低于VWAP时，通常表示市场处于下降趋势。VWAP还可以用作支撑和阻力水平的参考。
                    </p>
                    <p>
                        <strong className="bold-text">判断买卖时机：</strong> 交易者可以根据价格相对于VWAP的位置来判断买卖时机。例如，当价格突破VWAP并持续上涨时，可能是买入信号；而当价格跌破VWAP并持续下跌时，可能是卖出信号。
                    </p>

                    <h1 className='learn-title'>Williams %R:</h1>
                    <p>
                        Williams %R（Williams Percent Range）是一种技术分析工具，用于衡量市场的超买和超卖状态。其背后的逻辑是基于价格在一定时间段内的最高点和最低点之间的相对位置。
                    </p>
                    <h2 className='learn-sub-title'>使用方式:</h2>
                    <p>
                        <strong className="bold-text">计算百分比范围：</strong> Williams %R通过计算当前价格相对于一定时间段内的最高和最低价格的相对位置来得出一个值。计算公式为（最高价-当前价）除以（最高价-最低价），然后乘以-100。结果通常在-100到0之间波动。
                    </p>
                    <p>
                        <strong className="bold-text">识别超买超卖：</strong> Williams %R的数值可以帮助识别市场的超买和超卖状态。当Williams %R低于-80时，表示市场处于超卖状态，可能会出现价格反弹；而当Williams %R高于-20时，表示市场处于超买状态，可能会出现价格回调。
                    </p>
                    <p>
                        <strong className="bold-text">判断买卖时机：</strong> 交易者可以根据Williams %R的数值变化来判断买卖时机。例如，当Williams %R从超卖区域回升到-80以上时，可能是买入信号；而当Williams %R从超买区域回落到-20以下时，可能是卖出信号。此外，Williams %R的背离现象也可以用来判断潜在的趋势反转。
                    </p>
                </>
            )}

        </div>
    );
}

export default LearningPage;
